import React, {useState} from "react";
import {graphql, useStaticQuery} from "gatsby";

function PricingFeature ({value}) {
    return (
        <p className="flex items-center mb-2 text-gray-600">
            <span
                className="inline-flex items-center justify-center flex-shrink-0 w-4 h-4 mr-2 text-white bg-gray-500 rounded-full">
                <svg fill="none" stroke="currentColor" stroke-linecap="round"
                     stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3"
                     viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                </svg></span>{value}
        </p>
    );
}

function PricingPlan ({children, name, footer, popular, prices, term}) {
    return (
        <div className="w-full p-2 xl:w-1/4 md:w-1/2">
            <div
                className={`relative flex flex-col justify-between h-full p-6 overflow-hidden border ${popular ? "border-blue-600" : "border-gray-300"} rounded-lg`}>
                { popular &&
                    <span className="absolute top-0 right-0 px-3 py-1 text-xs tracking-widest text-white bg-blue-600 rounded-bl">POPULAR</span>
                }
                <div className="relative flex flex-col w-full">

                    {/* Name */}
                    <h2 className="mb-1 text-sm font-medium tracking-widest uppercase">{name}</h2>

                    {/* Price */}
                    <h1 className="flex items-center pb-4 mb-4 text-5xl leading-none text-gray-900 border-b border-gray-200">
                        <span>$<span>{prices[term]}</span></span>
                        <span className="ml-1 text-lg font-normal text-gray-500">/<span>{term === 'month' ? "mo" : "yr"}</span></span>
                    </h1>

                    {/* Contents */}
                    {children}
                </div>
                <div className="w-full">
                    <button
                        className={`inline-flex items-center justify-center w-full px-4 py-3 text-base font-medium leading-6 whitespace-no-wrap border rounded-md shadow-sm focus:outline-none 
                        ${ popular ? "text-white bg-blue-600 border-blue-700 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" 
                            : "text-gray-600 bg-white border-gray-300 hover:bg-gray-50 focus:shadow-none"}`}>
                        Get Started
                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                             stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2"
                             viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </button>

                    {/* Footer */}
                    <p className="mt-3 text-xs text-center text-gray-500">{footer}</p>
                </div>
            </div>
        </div>
    );
}

export default function Pricing() {
    // TODO i18n acronym

    const [term, setTerm] = useState('month');

    // TODO Get prices from backend (e.g. Stripe) ? using Gastby plugin (if exist) ?
    const data = useStaticQuery(graphql`{
          allPricingJson {
            edges {
              node {
                price {
                  month
                  year
                }
                name
                popular
                footer
                features
              }
            }
          }
        }`);

    const { edges: plans } = data.allPricingJson;

    return (


        <section className="overflow-hidden text-gray-700 bg-white">

            <div className="container px-5 pt-20 pb-24 mx-auto">
                <div className="flex flex-col w-full mb-20 text-center">
                    <h1 className="mb-2 text-3xl font-medium text-gray-900 sm:text-4xl">Pricing Plans</h1>
                    <p className="mx-auto text-lg leading-relaxed text-gray-500 lg:w-2/3">
                        We have fine tuned our pricing to fit businesses of any size.</p>
                    <div
                        className="flex items-center justify-between mx-auto mt-6 overflow-hidden text-lg font-bold border border-blue-600 rounded-lg">
                        <button className={`px-6 leading-none h-11 focus:outline-none ${ term === 'month' ? "text-white bg-blue-600" : "text-blue-500 hover:text-blue-600"}`}
                                onClick={() => setTerm('month')}>Monthly</button>
                        <button className={`px-6 leading-none h-11 focus:outline-none ${ term === 'year' ? "text-white bg-blue-600" : "text-blue-500 hover:text-blue-600"}`}
                                onClick={() => setTerm('year')}>Yearly</button>
                    </div>
                </div>

                <div className="flex flex-wrap">
                    {plans.map(({node: plan}) => {
                        return (
                            <PricingPlan name={plan.name} prices={{month: plan.price.month, year: plan.price.year}}
                                         footer={plan.footer} popular={plan.popular} term={term}>
                                {plan.features.map(feat =>{
                                    return <PricingFeature value={feat}/>
                                })}
                            </PricingPlan>
                        )
                    })}
                </div>
            </div>
        </section>
    );
}



