import React from "react";
import Pricing from "../components/pricing";
import Layout from "../components/layout";

const TailwindPricePage = () => (
    <Layout>
        <Pricing />
    </Layout>
);

export default TailwindPricePage;